.select{
    background-color: #222232 !important;
    border: 1px solid #48556F !important;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 4px 0px 8px 0px;
    width: 100%;

}

.select::placeholder {
    color: white !important;
}

.select:focus{
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}