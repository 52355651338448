.RegisteredTeam {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 18px;
    background-color: #0D0D1C;

    .teamContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .team,
        .tournament {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;

            .teamLogo {
                width: 60px;
                height: 60px;
                border-radius: 100%;
            }

            .tournamentLogo {
                width: 50px;
                height: 50px;
            }

            .nameContainer {
                padding-left: 10px;

                .location {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .icon {
                        font-size: .7rem;
                        color: #246BFD;
                        margin-right: 5px;
                    }
                }
            }
        }

    }

    .qrContainer {
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        .qrImage {
            width: 50px;
            height: 50px;
        }

    }
}