.Profile {
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media (max-width:768px) {
        overflow: auto;
        height: fit-content;

        .row {
            flex-direction: column !important;
            height: fit-content;

            .col1,
            .teamInfoSection {
                overflow: hidden;
                height: fit-content;
                width: 100% !important;
            }
        }

    }

    .row {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;

        .col {
            height: 100%;
            overflow: auto;
            padding: 0 10px;

            @media (max-width:991px) {
                padding: 0;
            }

            &.col1 {
                width: 41.667%;
            }

            &.teamInfoSection {
                width: 58.333%;
            }

            .userContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                min-width: 70%;
                width: fit-content;

                @media (min-width:768px) and (max-width:1024px) {
                    min-width: 70%;
                }

                .image {
                    width: 90px;
                    height: 90px;
                    border-radius: 100%;
                }

                .nameContainer {
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;

                    .email {
                        color: white;
                        opacity: .8;
                    }

                    .tag {
                        cursor: pointer;
                        width: fit-content;
                        min-width: 140px;
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 12px 15px;
                        margin-top: 5px;
                        border-radius: 20px;
                        background: linear-gradient(96.39deg, #FBBE21 6.05%, #E0931F 91.05%);
                        line-height: .7;
                        font-size: 14px;
                        text-align: center;
                        color: #101010;
                        font-weight: 400;

                    }
                }
            }

            .option {
                padding: 20px 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 18px;
                background-color: #0D0D1C;
                color: white;
                cursor: pointer;

                span.icon {
                    display: flex;
                    font-size: .7rem;
                    margin-right: 15px;
                }
            }

            .ticketContainer {
                width: 100%;
                padding: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 18px;
                background-color: #0D0D1C;

                .teamContainer {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .team,
                    .tournament {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 5px 0;

                        .teamLogo {
                            width: 60px;
                            height: 60px;
                            border-radius: 100%;
                        }

                        .tournamentLogo {
                            width: 50px;
                            height: 50px;
                            border-radius: 100%;
                        }

                        .nameContainer {
                            padding-left: 10px;

                            .location {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .icon {
                                    font-size: .7rem;
                                    color: #246BFD;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }

                }

                .qrContainer {
                    display: flex;
                    flex-direction: column;
                    padding-left: 10px;

                    .qrImage {
                        width: 50px;
                        height: 50px;
                    }

                }
            }

            &.teamInfoSection {
                overflow: hidden;

                .teamInfoContainer {
                    width: 100%;
                    height: 100%;
                    padding: 20px;
                    background: #0D0D1C;
                    overflow: auto;
                    border-radius: 18px;

                    .headerContainer {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .editIcon {
                            font-size: .7rem;
                            color: white;
                            margin-left: 20px;
                            cursor: pointer;
                        }
                    }

                    .ticketContainer {
                        border: 1px solid #48556F;
                        background: #222232;
                    }

                    .userContainer {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .image {
                            width: 50px;
                            height: 50px;
                            border-radius: 100%;
                        }



                        .coachBadge {
                            height: fit-content;
                            width: fit-content;
                            padding: 8px 12px;
                            margin: 0 10px;
                            border-radius: 20px;
                            background: linear-gradient(96.39deg, #FBBE21 6.05%, #E0931F 91.05%);
                            line-height: .7;
                            font-size: 12px;
                            text-align: center;
                            color: #101010;
                            font-weight: 400;
                        }

                        .messageButton {
                            height: fit-content;
                            width: fit-content;
                            padding: 8px 14px;
                            margin-left: 5px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border-radius: 12px;
                            font-size: 12px;
                            font-weight: 400;
                            border: none;

                            &:focus-visible,
                            &:focus-within {
                                outline: none;
                            }

                            .icon {
                                display: flex;
                                margin-right: 5px;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }



}

.messageButton {
    height: fit-content;
    width: fit-content;
    padding: 8px 14px;
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 400;
    border: none;

    &:focus-visible,
    &:focus-within {
        outline: none;
    }

    .icon {
        display: flex;
        margin-right: 5px;
        font-size: 1rem;
    }
}

.modalContainerAddKids {
    padding: 30px;
    width: 100%;
    max-width: 320px;
    height: fit-content;
    // max-height: 400px;
    border-radius: 18px;
    background-color: #0D0D1C;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 4;
    overflow: scroll;
}

.removeWebKit {
    -webkit-mask: none !important;
}

.modalContainer {
    padding: 20px 30px;
    width: 100%;
    max-width: 440px;
    height: fit-content;
    // max-height: 400px;
    border-radius: 18px;
    background-color: #0D0D1C;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 4;
    overflow: scroll;
    -webkit-mask: radial-gradient(30px, rgba(0, 0, 0, 0) 98%, #000) 160px -45px/320px 402.4px;

    .image {
        width: 70px;
        height: 70px;
        border-radius: 100%;
    }

    .location {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
            font-size: .7rem;
            color: #246BFD;
            margin-right: 5px;
        }

    }

    hr {
        border-top: 3px dashed #2f3053 !important;
        width: 90%;
    }

    .qrImage {
        width: 100px;
        height: 100px;
    }

    .shareButton {
        padding: 12px 10px;
        font-size: 20px;
        color: white;
        background: linear-gradient(90deg, #181829 0%, #181829 35%);
        border: 1px solid #48556F;
        border-radius: 16px;
        width: 100%;

        &:focus-visible,
        &:focus-within {
            outline: none;
        }
    }
}

.cardBtn {
    border: 1px solid #0AA7FF;
}

.panelBtn {
    border-radius: 16px;
    padding: 15px 25px !important;
    font-weight: 500;
    font-size: medium;
}

.panelBtn {
    border-radius: 26px;
    padding: 15px 25px !important;
    font-weight: 500;
    font-size: medium;
    color: black;
    border: 14px solid #0D0D1C;
}

.orangeBg {
    background: linear-gradient(90deg, rgba(251, 190, 33, 1) 0%, rgba(224, 147, 31, 1) 35%);
    /* padding: 25px 30px !important; */
}

span.liveBadge {
    height: fit-content;
    padding: 4px 15px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF3C3C;
    border-radius: 18px;
    color: white;
    font-size: 10px;
}