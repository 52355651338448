.modalContainer {
    padding: 10px 20px;
    width: 90%;
    max-width: 800px;
    /* height: fit-content; */
    height: 80%;
    max-height: 600px;
    border-radius: 18px;
    background-color: #181829;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 4;
    overflow: hidden;
}

.modalContainer::before {
    content: "";
    position: absolute;
    height: 100%;
    inset: 0;
    border-radius: 18px;
    padding: 0.6px;
    background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

.container {
    overflow: auto;
}

.contentContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.container {
    width: 100%;
}