.MatchCard {
    width: 100%;
    padding: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .matchLogo {
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }

    .opponentContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;


        div.teamLogoContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img.teamLogo {
                width: 25px;
                height: 25px;
                border-radius: 100%;
            }

            span.score {
                text-align: center;
                display: flex;
                color: white;
                font: 1rem;
                font-weight: 500;
            }
        }

        .separator {
            display: flex;
            color: white;
            margin: 0 10px;
            font-size: 1rem;
            font-weight: 500;
        }

    }
}