.Inbox {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #0D0D1C;
    border-radius: 18px;

    .titleContainer {
        width: 100%;
        padding: 15px;
        border-radius: 15px;
        background-color: #282831;

        .backButton {
            border: 1px solid #48556F;
            padding: 6px;
            font-size: 1.3rem;
            border-radius: 100%;
            display: inline-flex;
            color: white;
            cursor: pointer;
            margin-right: 10px;
        }

        .userContainer {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            .imageContainer {
                display: flex;
                position: relative;
                align-items: center;

                .image {
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                }

                .online {
                    padding: 5px;
                    border-radius: 100%;
                    border: 2px solid white;
                    background: #01C7A3;
                    position: absolute;
                    bottom: 3px;
                    right: 3px;
                    z-index: 1;
                }
            }

            .nameContainer {
                width: 100%;
                padding-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .online {
                    margin: 0;
                    font-size: 0.78rem;
                    color: #FCFCFC;
                    opacity: .8;
                }
            }
        }
    }

    .chatContainer {
        width: 100%;
        height: 100%;
        padding: 15px;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .chat {
            width: fit-content;
            max-width: 50%;
            margin: 8px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.me {
                margin-left: auto;
                align-items: flex-end;

            }

            .info {
                width: fit-content;
                color: white;
                opacity: .4;
                font-size: .7rem;
            }

            .message {
                padding: 10px 15px;
                border-radius: 100%;
                margin: 0;
                background: #222232;
                border-radius: 0px 10px 10px 10px;
                color: white;
                font-size: .85rem;

                &.me {
                    border-radius: 10px 0 10px 10px;
                    background-color: #0AA7FF;
                }
            }
        }
    }

    .messageContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;

        .sendButton {
            padding: 8px 10px;
            border-radius: 100%;
            border: none;
            font-size: 1.2rem;
            margin-left: 10px;

            &:focus-within,
            &:focus-visible {
                outline: none;
            }
        }
    }
}