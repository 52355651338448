.ChatThreads {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .titleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;

        

        .searchButton {
            padding: 8px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            color: white;
            background: #0D0D1C;
            border-radius: 8px;

        }

    }

    .threads {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .thread {
            width: -webkit-fill-available;
            height: fit-content;
            padding: 5px;
            margin: 10px;
            border-radius: 18px;
            display: flex;
            flex-direction: row;

            .userImage {
                max-width: 50px;
                max-height: 50px;
                border-radius: 100%;
            }

            .userInfo {
                width: 100%;
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .nameContainer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .indicator {
                        height: fit-content;
                        margin-left: 8px;
                        padding: 4px;
                        background: #DB4097;
                        border-radius: 100%;
                    }
                }

                .message {
                    margin: 0;
                    font-size: 0.78rem;
                    color: #FCFCFC;
                    opacity: .8;
                }
            }


        }

    }
}