.EditProfile {
    width: 100%;
    height: 100%;
    overflow: auto;

    .form {
        margin: auto;
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .imageContainer {
            display: flex;
            position: relative;

            .fileChooseInput {
                position: absolute;
                top: 0;
                left: 0;
            }

            .label {
                user-select: none;
                cursor: pointer;
            }

            .userImage {
                width: 110px;
                height: 110px;
                border-radius: 100%;

            }

        }

        .error {
            color: "red";
            font-size: "12px";
        }




    }
}