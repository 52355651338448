.medal-img img {
    width: 50px;
    height: 50px;
}

.bracket .bracket-custom .dWYBbN {
    /* Frame 8 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 18.22px; */
    gap: 18.22px;


    background: rgba(13, 13, 28, 0.85);
    border-radius: 25px;
    border: 1px solid #2B3C8E;
}

.bracket .bracket-custom .sc-imWYAI:nth-child(2n+1):not(:last-child)::after {
    border-right: 1px solid rgb(10 167 255);
}

.bracket .bracket-custom .sc-imWYAI:nth-child(2n+1):not(:last-child)::after {
    border-top: 1px solid rgb(10, 167, 255)
}

.bracket .bracket-custom .sc-imWYAI:nth-child(2n)::after {
    border-right: 1px solid rgb(10 167 255);
}

.bracket .bracket-custom .jknkFS:nth-child(2n)::after {
    border-right: 1px solid rgb(10 167 255);
}

.bracket .bracket-custom .jknkFS:nth-child(2n)::after {
    border-bottom: 1px solid rgb(10 167 255);
}

.bracket .bracket-custom .jknkFS:nth-child(2n)::before {
    border-top: 1px solid rgb(10 167 255);
}

.seed.winner {
    /* Add your individual CSS styles for the winner team */
    background: linear-gradient(96.39deg, #FBBE21 6.05%, #E0931F 91.05%) !important;
    /* Example background color */
    font-weight: bold;
    padding: 10px;
    /* Example font weight */
}


.bracket .bracket-custom-left .dWYBbN {
    /* Frame 8 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 18.22px; */
    gap: 18.22px;


    background: rgba(13, 13, 28, 0.85);
    border-radius: 25px;
    border: 1px solid #2B3C8E;
}

.bracket .bracket-custom-left .sc-imWYAI:nth-child(2n+1):not(:last-child)::after {

    border-left: 1px solid rgb(10 167 255);
}

.bracket .bracket-custom-left .sc-imWYAI:nth-child(2n+1):not(:last-child)::after {
    border-top: 1px solid rgb(10, 167, 255)
}

.bracket .bracket-custom-left .sc-imWYAI:nth-child(2n)::after {
    border-left: 1px solid rgb(10 167 255);
}

.bracket .bracket-custom-left .jknkFS:nth-child(2n)::after {
    border-left: 1px solid rgb(10 167 255);
}

.bracket .bracket-custom-left .jknkFS:nth-child(2n)::after {
    border-bottom: 1px solid rgb(10 167 255);
}

.bracket .bracket-custom-left .jknkFS:nth-child(2n)::before {
    border-top: 1px solid rgb(10 167 255);
}